<template>
    <h3 class="text-lg leading-6 font-medium text-gray-900">
        <slot></slot>
    </h3>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'SectionHeader',
    });
</script>
