<template>
    <div>
        <ModalButton
            theme="white"
            label="Back"
            @click="onBack"
        />
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FormActions',
        props: {
            onEdit: Function,
            onDelete: Function,
            onBack: Function,
        },
        components: {
            ModalButton,
        },
    });
</script>
