<template>
    <PageWrapper>
        <ConfirmDeleteModal
            title="Delete Location"
            confirmationText="Are you sure you want to delete this location?  This action cannot be undone."
            :open="confirmDeleteModal.isOpen"
            @delete="onDeleteLocation"
            @cancel="onConfirmDeleteCancel"
            @close="onConfirmDeleteCancel"
        />
        <div class="grid">
            <PageIntro>
                <FormActions :onBack="onExit" />
                <ActionsMenu
                    class="flex justify-end content-center mt-3 md:mt-0"
                >
                    <div
                        v-if="!isEditing"
                        class="py-1"
                    >
                        <MenuItem
                            v-if="isNotDataAnalyst"
                            :disabled="isBusy || !hasCleanLocation"
                            v-slot="{ active, disabled }"
                        >
                            <a
                                href="#"
                                @click.prevent="!disabled && onShowEdit()"
                                :class="[
                                    active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                    disabled
                                        ? 'bg-gray-200 text-gray-800 cursor-not-allowed'
                                        : '',
                                    'group flex items-center px-4 py-2 text-sm',
                                ]"
                            >
                                <PencilAltIcon
                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                                Edit
                            </a>
                        </MenuItem>
                    </div>
                    <div
                        v-if="isEditing"
                        class="py-1"
                    >
                        <MenuItem
                            :disabled="
                                isBusy || isExporting || !hasCleanLocation
                            "
                            v-slot="{ active, disabled }"
                        >
                            <a
                                href="#"
                                @click.prevent="!disabled && onEditCancel()"
                                :class="[
                                    active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                    disabled
                                        ? 'bg-gray-200 text-gray-800 cursor-not-allowed'
                                        : '',
                                    'group flex items-center px-4 py-2 text-sm',
                                ]"
                            >
                                <XCircleIcon
                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                                Cancel
                            </a>
                        </MenuItem>
                    </div>
                    <div class="py-1">
                        <MenuItem
                            :disabled="
                                isExporting || isEditing || !hasCleanLocation
                            "
                            v-slot="{ active, disabled }"
                        >
                            <a
                                href="#"
                                @click.prevent="
                                    !disabled &&
                                        onExport(cleanLocationDetails.resource)
                                "
                                :class="[
                                    active
                                        ? 'bg-gray-100 text-gray-900'
                                        : 'text-gray-700',
                                    disabled
                                        ? 'bg-gray-200 text-gray-800 cursor-not-allowed'
                                        : '',
                                    'group flex items-center px-4 py-2 text-sm',
                                ]"
                            >
                                <DocumentDownloadIcon
                                    class="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                    aria-hidden="true"
                                />
                                Export
                            </a>
                        </MenuItem>
                    </div>
                    <div class="py-1">
                        <MenuItem
                            v-if="isNotDataAnalyst"
                            :disabled="!hasCleanLocation"
                            v-slot="{ active, disabled }"
                        >
                            <a
                                href="#"
                                @click.prevent="
                                    !disabled && onShowConfirmDelete()
                                "
                                :class="[
                                    active
                                        ? 'bg-red-100 text-red-900'
                                        : 'text-red-700',
                                    'group flex items-center px-4 py-2 text-sm',
                                ]"
                            >
                                <TrashIcon
                                    class="mr-3 h-5 w-5 text-red-400 group-hover:text-red-500"
                                    aria-hidden="true"
                                />
                                Delete
                            </a>
                        </MenuItem>
                    </div>
                </ActionsMenu>
            </PageIntro>
        </div>
        <LoadingWrapper :isLoading="!form.isInitialized || !hasCleanLocation">
            <EditLocationForm
                v-if="hasCleanLocation"
                :form="form"
                :isBusy="isBusy"
            />
            <div
                class="grid grid-cols-1 mt-6 mb-6"
                v-if="
                    !isEditing && !isSaving && !isRefreshing && hasCleanLocation
                "
            >
                <DatasetBatchesTable
                    v-if="hasCleanLocation"
                    :form="form"
                    :isBusy="isBusy"
                />
            </div>
        </LoadingWrapper>
    </PageWrapper>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        computed,
        onActivated,
        onDeactivated,
        onBeforeMount,
    } from 'vue';
    import { useStore } from 'vuex';
    import router from '@/router';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import PageWrapper from '@/components/PageWrapper.vue';
    import PageIntro from '@/components/PageIntro.vue';
    import FormActions from '@/components/FormActions.vue';
    import ActionsMenu from '@/components/ActionsMenu.vue';
    import { MenuItem } from '@headlessui/vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import {
        DocumentDownloadIcon,
        PencilAltIcon,
        TrashIcon,
        XCircleIcon,
    } from '@heroicons/vue/solid';
    import EditLocationForm from '~DataManager/components/form/EditLocationForm.vue';
    import DatasetBatchesTable from '~DataManager/components/grid/DatasetBatchesTable.vue';

    // <!-- COMPOSABLES -->
    import {
        LocationFormProps,
        useLocationForm,
    } from '~DataManager/hooks/useLocationForm';
    import {
        LocationExportProps,
        useLocationExport,
    } from '~DataManager/hooks/useLocationExport';
    import { useDataAnalystGate } from '@/hooks/gates';
    import { useNARAStandardsData } from '../hooks/useNARAStandardOptions';
    import DateRangeFilter from '@/components/sidebar/DateRangeFilter.vue';

    // <!-- TYPES -->

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'LocationDetail',
        components: {
            PageWrapper,
            PageIntro,
            FormActions,
            ActionsMenu,
            MenuItem,
            PencilAltIcon,
            TrashIcon,
            DocumentDownloadIcon,
            XCircleIcon,
            DatasetBatchesTable,
            ConfirmDeleteModal,
            EditLocationForm,
            LoadingWrapper,
        },
        setup(props, context) {
            // PROPS (EXPORTER)
            const exporterProps = new LocationExportProps(props);
            exporterProps.onError = async () => {};
            exporterProps.onExport = async () => {};
            const exporter = useLocationExport(exporterProps, context);

            // PROPS (FORM)
            const formProps = new LocationFormProps(props);
            formProps.onInit = async (config) => {
                config.state.targetLocation.value = null;
                await onRefresh();
            };
            formProps.onExit = async () => {
                await router.push('/data-manager');
            };
            const form = useLocationForm(formProps, context);

            // STATE
            const { confirmDeleteModal, cleanLocationDetails } = form.state;
            const {
                isBusy,
                isRefreshing,
                isEditing,
                isSaving,
                hasCleanLocation,
            } = form.properties;

            const user = computed(() => {
                return store.state.users.me;
            });

            const { isNotDataAnalyst } = useDataAnalystGate();

            // STATUS
            const { isExporting } = exporter.properties;

            // EVENT HANDLERS
            const { onExport } = exporter.handlers;
            const {
                onExit,
                onRefresh,
                onShowEdit,
                onEditCancel,
                onShowConfirmDelete,
                onConfirmDeleteCancel,
                onDelete: onDelete,
            } = form.handlers;

            const onDeleteLocation = async () => {
                try {
                    await onDelete();
                    await onExit();
                } catch (err) {
                    console.error(err);
                    await onConfirmDeleteCancel();
                }
            };

            const store = useStore();
            const { fetchData: fetchNARAStandardData } =
                useNARAStandardsData(store);

            // LIFECYCLE
            onBeforeMount(async () => {
                await fetchNARAStandardData();
            });

            onActivated(async () => {
                await onRefresh();
            });

            onDeactivated(async () => {
                form.methods.closeFormModals();
            });

            return {
                // <!-- EXPORTER -->
                isExporting,

                onExport,

                // <!-- FORM -->
                form,
                confirmDeleteModal,

                isBusy,
                isRefreshing,
                isEditing,
                isSaving,

                hasCleanLocation,
                cleanLocationDetails,

                onExit,

                onShowEdit,
                onEditCancel,

                onShowConfirmDelete,
                onConfirmDeleteCancel,
                onDeleteLocation,

                user,
                isNotDataAnalyst,
            };
        },
    });
</script>
