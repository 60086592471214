<template>
    <Panel>
        <template #header>
            <div class="flex flex-row justify-between">
                <div class="text-2xl text-black font-bold">
                    {{ title }}
                </div>
            </div>
        </template>
        <template #default>
            <LoadingWrapper :isLoading="isSaving">
                <FormKit
                    id="location-details-form"
                    type="form"
                    v-model="dirtyLocationDetails.resource"
                    :actions="false"
                    :errors="formErrors"
                    :config="config"
                    #default="context"
                    preserve
                >
                    <!-- Info section inputs are not dynamically generated as a stop-gap time saver. Rather than
        figuring out how to add the location hierarchy as a custom generated component, for now, Leo has merely
        written out this section by hand. This works but does have the potential to be a maintainence burden in
        the future, if it starts being a problem do not hesistate to change it -->
                    <!-- https://formkit.com/essentials/forms -->
                    <FormSection
                        class="pt-4"
                        title="Location Information"
                    >
                        <template #description>
                            <div v-if="isNARAEnabled">
                                <p>
                                    eClimateNotebook uses five fields or levels
                                    to identify and describe a monitoring space.
                                    The
                                    <span class="font-bold"
                                        >Location Hierarchy</span
                                    >
                                    consists of four levels, with the fifth
                                    level the
                                    <span class="font-bold">Location Name</span
                                    >. These are combined in the
                                    <span class="font-bold"
                                        >Location Display Name</span
                                    >
                                    seen on graphs, tables, and reports.
                                </p>
                                <p class="pt-2">
                                    NARA uses consistent terminology and
                                    abbreviations for choices in the four
                                    Location Hierarchy levels.
                                </p>
                                <p>
                                    <span class="font-bold"
                                        >Level 1 - Building</span
                                    >.
                                    <span class="font-bold"
                                        >Level 2 - Administration</span
                                    >
                                    (AO-Archives Office. FRC-Records Center.
                                    PLA-Presidential Library Archives.
                                    PLM-Presidential Library Museum).
                                    <span class="font-bold"
                                        >Level 3 - Space Type</span
                                    >
                                    (ST-Storage. WR-Workroom. EX-Exhibition.
                                    RR-Research Room).
                                    <span class="font-bold"
                                        >Level 4 - Monitoring Site</span
                                    >
                                    (space name/number or a custom identifier).
                                    The fifth level
                                    <span class="font-bold">Location Name</span>
                                    is the specific monitoring location in the
                                    space. In storage areas this may start with
                                    UL (upper level shelf) or LL (lower level
                                    shelf) followed by the minimum HMS or ARCIS
                                    information needed to find the datalogger.
                                    In workrooms or exhibition areas a site
                                    location such as desk, wall, or case could
                                    be used.
                                </p>
                            </div>
                            <div v-if="isNARADisabled">
                                <p>
                                    eClimateNotebook requires five designations
                                    to identify and describe a monitored space.
                                    The
                                    <span class="font-bold"
                                        >Location Hierarchy</span
                                    >
                                    consists of four levels and the final
                                    designation (or level) is the
                                    <span class="font-bold">Location Name</span
                                    >. The hierarchy levels and location name
                                    are displayed together to create the
                                    <span class="font-bold"
                                        >Location Display Name</span
                                    >
                                    on graphs, tables, and reports.
                                </p>
                                <p class="pt-2">
                                    Environmental management teams should work
                                    collaboratively to develop consistent
                                    terminology for each level of the location
                                    hierarchy to create a structure that is
                                    intuitive to both collections and facilities
                                    staff. For example, Second Floor, 2nd Fl.,
                                    Two, or 2 can all refer to the same floor
                                    but will be recognized as different floor
                                    levels in the hierarchy.
                                    <span class="font-bold"
                                        >Determine which terminology is
                                        preferred and use it consistently.</span
                                    >
                                    Labels can be assigned to each hierarchy
                                    level, for example Site (level 1), Building
                                    (level 2), Floor (level 3), and Room (level
                                    4), to assist with naming conventions and
                                    consistency.
                                </p>
                            </div>
                        </template>
                        <template #default>
                            <div class="col-span-3">
                                <HorizontalRule></HorizontalRule>
                                <AddLocationHierarchyFormSection
                                    :form="form"
                                    :currentHierarchy="
                                        dirtyLocationDetails.hierarchy
                                    "
                                    @input="
                                        dirtyLocationDetails.onHierarchyInput
                                    "
                                    ref="hierarchyComponent"
                                />
                            </div>
                            <FormKit
                                id="name"
                                type="text"
                                label="* Location Name"
                                name="name"
                                :classes="{
                                    outer: isRefreshing
                                        ? 'px-4 sm:px-2 animate-pulse col-span-3'
                                        : 'px-4 sm:px-2 col-span-3',
                                    inner: isEditing
                                        ? '$reset w-full border-none'
                                        : '$reset w-full border-none',
                                    input: isEditing
                                        ? '$reset w-full border-1 border-gray-500 text-black rounded-lg overflow-x-auto'
                                        : '$reset w-full border-none px-3 cursor-not-allowed hover:text-gray-600 overflow-x-auto',
                                }"
                                placeholder="Enter Location name..."
                                :disabled="!isEditing || isBusy"
                                validation="required|length:1,"
                                valdiation-name="Location Name"
                            />
                        </template>
                    </FormSection>
                    <FormSection class="pt-4">
                        <FormKit
                            v-for="(metadata, index) in sections.dataLogger"
                            :key="`info-section-${index}`"
                            :type="metadata.type"
                            :label="metadata.label"
                            :name="metadata.name"
                            :classes="{
                                outer: isRefreshing
                                    ? 'px-4 sm:px-2 animate-pulse w-full'
                                    : 'px-4 sm:px-2 w-full',
                            }"
                            :placeholder="
                                isEditing
                                    ? 'Enter details here...'
                                    : 'No information provided.'
                            "
                            :disabled="!isEditing || isBusy"
                            :innerClass="isEditing ? '' : 'border-none'"
                        />
                        <FormKit
                            v-if="isNARAEnabled"
                            id="nara-standard"
                            type="select"
                            name="standard"
                            placeholder="Select Standard"
                            :classes="{
                                outer: isRefreshing
                                    ? 'px-4 sm:px-2 animate-pulse'
                                    : 'px-4 sm:px-2',
                                input: isEditing
                                    ? ''
                                    : 'appearance-none not-isEditing px-3 py-2 h-10 text-base text-gray-700 border-none rounded-lg hover:cursor-not-allowed',
                            }"
                            v-model="dirtyLocationDetails.standard"
                            :options="naraStandardOptions"
                            @input="onNARAStandardInput"
                            :disabled="!isEditing || isBusy"
                            :validation="[['required']]"
                            valdiation-name="NARAStandard"
                            :ignore="true"
                        >
                            <template #label>
                                <div class="block mb-1 font-bold text-sm">
                                    <div class="inline font-bold">
                                        NARA Standard
                                    </div>
                                    <TeleportTrigger
                                        class="inline"
                                        @open="standardsModal.open"
                                    />
                                </div>
                            </template>
                        </FormKit>
                        <FormKit
                            id="timezone"
                            type="select"
                            label="* Time Zone"
                            name="timezone"
                            :value="dirtyLocationDetails.resource.timezone"
                            :classes="{
                                outer: isRefreshing
                                    ? 'px-4 sm:px-2 animate-pulse'
                                    : 'px-4 sm:px-2',
                                input: isEditing
                                    ? ''
                                    : 'appearance-none not-isEditing px-3 py-2 h-10 text-base text-gray-700 border-none rounded-lg hover:cursor-not-allowed',
                            }"
                            :options="timezoneOptions"
                            @input="onTimezoneInput"
                            :disabled="!isEditing || isBusy"
                            :validation="[
                                ['required'],
                                ['*+not', 'placeholder', ...legacyTimezones],
                            ]"
                            valdiation-name="Timezone"
                        />
                        <ViewNARAStandardsModal
                            :show="standardsModal.isOpen.value"
                            @close="standardsModal.close"
                        />
                    </FormSection>
                    <FormSection class="pt-4">
                        <span>
                            <FormKit
                                id="photo"
                                type="file"
                                label="Photo"
                                accept=".png,.jpeg,.jpg"
                                :help="
                                    !!dirtyLocationDetails.photo.remove
                                        ? 'Cannot upload file while removing existing file.'
                                        : 'Supported file types: .png, .jpg, .jpeg'
                                "
                                name="photo"
                                :classes="{
                                    fileList: 'w-full',
                                    fileItem: 'w-full',
                                    fileName: 'hidden',
                                    outer: isRefreshing
                                        ? 'px-4 sm:px-2 animate-pulse'
                                        : 'px-4 sm:px-2',
                                    input: isEditing
                                        ? '$reset px-0 pb-1'
                                        : 'hidden',
                                    help: isEditing ? '' : 'hidden',
                                    removeFiles:
                                        isEditing &&
                                        dirtyLocationDetails.hasPhotoFileSelected
                                            ? 'sn-photo-link underline'
                                            : 'hidden',
                                    noFiles: 'hidden',
                                }"
                                v-model="dirtyLocationDetails.photo.value"
                                @input="onFileInput"
                                :disabled="
                                    !isEditing ||
                                    isBusy ||
                                    !!dirtyLocationDetails.photo.remove
                                "
                                :ignore="true"
                            >
                                <template #label>
                                    <span class="block mb-1 font-bold text-sm"
                                        >Photo
                                    </span>
                                    <div
                                        v-if="!isEditing"
                                        class="w-full h-10 px-3 py-2 rounded-lg"
                                    >
                                        <a
                                            class="sn-photo-link hover:cursor-pointer underline"
                                            v-if="
                                                cleanLocationDetails.hasPhotoURL
                                            "
                                            href="#"
                                            @click="onShowPhoto"
                                        >
                                            (View)</a
                                        >
                                        <span v-else>
                                            No photo available.
                                        </span>
                                    </div>
                                </template>
                            </FormKit>
                            <FormKit
                                v-if="
                                    isEditing &&
                                    cleanLocationDetails.hasPhotoURL
                                "
                                id="remove-photo"
                                type="checkbox"
                                label="Remove Existing?"
                                name="remove-photo"
                                help="Check to delete existing photo on save."
                                :classes="{
                                    outer: isRefreshing
                                        ? 'px-4 sm:px-2 animate-pulse'
                                        : 'px-4 sm:px-2',
                                    wrapper:
                                        '$reset formkit-wrapper flex flex-row h-8 space-x-2',
                                    label: '$reset formkit-label text-sm h-8',
                                    inner: '$reset formkit-inner text-sm h-8',
                                }"
                                v-model="dirtyLocationDetails.photo.remove"
                                @input="onFileRemoveInput"
                                :disabled="isBusy"
                                :ignore="true"
                            />
                        </span>
                        <span>
                            <FormKit
                                id="floorplan"
                                type="file"
                                label="Floor Plan"
                                accept=".png,.jpeg,.jpg"
                                :help="
                                    !!dirtyLocationDetails.floorplan.remove
                                        ? 'Cannot upload file while removing existing file.'
                                        : 'Supported file types: .png, .jpg, .jpeg'
                                "
                                name="floorplan"
                                v-model="dirtyLocationDetails.floorplan.value"
                                :classes="{
                                    fileList: 'w-full',
                                    fileItem: 'w-full',
                                    fileName: 'hidden',
                                    outer: isRefreshing
                                        ? 'px-4 sm:px-2 animate-pulse'
                                        : 'px-4 sm:px-2',
                                    input: isEditing
                                        ? '$reset px-0 pb-1'
                                        : 'hidden',
                                    help: isEditing ? '' : 'hidden',
                                    removeFiles:
                                        isEditing &&
                                        dirtyLocationDetails.hasFloorplanFileSelected
                                            ? 'sn-photo-link underline'
                                            : 'hidden',
                                    noFiles: 'hidden',
                                }"
                                @input="onFileInput"
                                :disabled="
                                    !isEditing ||
                                    isBusy ||
                                    !!dirtyLocationDetails.floorplan.remove
                                "
                                :ignore="true"
                            >
                                <template #label>
                                    <span class="block mb-1 font-bold text-sm"
                                        >Floor Plan
                                    </span>
                                    <div
                                        v-if="!isEditing"
                                        class="w-full h-10 px-3 py-2 rounded-lg"
                                    >
                                        <a
                                            class="sn-photo-link hover:cursor-pointer underline"
                                            v-if="
                                                cleanLocationDetails.hasFloorplanURL
                                            "
                                            href="#"
                                            @click="onShowFloorplan"
                                            >(View)</a
                                        >
                                        <span v-else>
                                            No floor plan available.
                                        </span>
                                    </div>
                                </template>
                            </FormKit>
                            <FormKit
                                v-if="
                                    isEditing &&
                                    cleanLocationDetails.hasFloorplanURL
                                "
                                id="remove-floorplan"
                                type="checkbox"
                                label="Remove Existing?"
                                name="remove-floorplan"
                                help="Check to delete existing floor plans on save."
                                :classes="{
                                    outer: isRefreshing
                                        ? 'px-4 sm:px-2 animate-pulse'
                                        : 'px-4 sm:px-2',
                                    wrapper:
                                        '$reset formkit-wrapper flex flex-row h-8 space-x-2',
                                    label: '$reset formkit-label text-sm h-8',
                                    inner: '$reset formkit-inner text-sm h-8',
                                }"
                                v-model="dirtyLocationDetails.floorplan.remove"
                                @input="onFileRemoveInput"
                                :disabled="isBusy"
                                :ignore="true"
                            />
                        </span>
                    </FormSection>
                    <FormSection
                        class="pt-4"
                        title="Administrative Information"
                    >
                        <FormKit
                            v-for="(metadata, index) in sections.admin"
                            :key="`info-section-${index}`"
                            :type="metadata.type"
                            :label="metadata.label"
                            :name="metadata.name"
                            :classes="{
                                outer: isRefreshing
                                    ? 'px-4 sm:px-2 animate-pulse w-full'
                                    : 'px-4 sm:px-2 w-full',
                            }"
                            :placeholder="
                                isEditing
                                    ? 'Enter details here...'
                                    : 'No information provided.'
                            "
                            :disabled="!isEditing || isBusy"
                            :innerClass="isEditing ? '' : 'border-none'"
                        />
                    </FormSection>
                    <FormSection
                        class="pt-4"
                        title="Mechanical System Information"
                    >
                        <FormKit
                            v-for="(metadata, index) in sections.mechanical"
                            :key="`info-section-${index}`"
                            :type="metadata.type"
                            :label="metadata.label"
                            :name="metadata.name"
                            :classes="{
                                outer: isRefreshing
                                    ? 'px-4 sm:px-2 animate-pulse w-full'
                                    : 'px-4 sm:px-2 w-full',
                            }"
                            :placeholder="
                                isEditing
                                    ? 'Enter details here...'
                                    : 'No information provided.'
                            "
                            :disabled="!isEditing || isBusy"
                            :innerClass="isEditing ? '' : 'border-none'"
                        />
                    </FormSection>
                    <LoadingWrapper
                        v-if="isEditing"
                        :isLoading="isSaving"
                    >
                        <div class="flex flex-row-reverse pt-5">
                            <ModalButton
                                v-if="isEditing"
                                theme="primary"
                                label="Save"
                                @click="onCreateLocation(context.state)"
                                :disabled="isLoading || !checkIfDirty()"
                            />
                            <ModalButton
                                theme="white"
                                label="Cancel"
                                @click="onAddCancel"
                            />
                        </div>
                    </LoadingWrapper>
                    <!-- DEBUG INFORMATION -->
                    <div
                        v-if="!!debug"
                        class="bg-gray-200 m-2 p-2 indent-0.5"
                    >
                        <pre
                            class="w-full text-xs subpixel-antialiased whitespace-pre-wrap break-words"
                            >{{ getDebugInfo(context) }}
            </pre
                        >
                    </div>
                </FormKit>
            </LoadingWrapper>
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import { defineComponent, toRefs, ref, reactive, onMounted } from 'vue';
    import { useStore } from 'vuex';

    // <!-- UTILITIES -->
    import { getLegacyTimezones } from '@/utils/timezone';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import TeleportTrigger from '@/components/modals/TeleportTrigger.vue';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import AddLocationHierarchyFormSection from '~DataManager/components/form/AddLocationHierarchyFormSection.vue';
    import ViewNARAStandardsModal from '~Analysis/components/modals/ViewNARAStandardsModal.vue';
    import { HorizontalRule } from '@/components/vnodes/HorizontalRule.js';

    // <!-- COMPOSABLES -->
    import { useFormkitDebugger } from '@/utils/FormKitDebugger';
    import { LocationFormConfig } from '~DataManager/hooks/useLocationForm';
    import { useNARAStandardOptions } from '~DataManager/hooks/useNARAStandardOptions';
    import { useModalToggle } from '@/hooks/modals';
    import { useNARAFeature } from '@/utils/features';

    // <!-- TYPES -->

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AddLocationForm',
        props: {
            /** Form configuration. */
            form: {
                /** @type {Vue.PropType<LocationFormConfig>} */
                type: Object,
                required: true,
            },
            /** Debug mode. */
            debug: {
                /** @type {Vue.PropType<Boolean>} */
                type: Boolean,
                default: false, // HACK: Set to true to get debug mode content.
            },
        },
        components: {
            Panel,
            FormSection,
            ModalButton,
            LoadingWrapper,
            AddLocationHierarchyFormSection,
            ViewNARAStandardsModal,
            TeleportTrigger,
            HorizontalRule,
        },
        setup(props, context) {
            /** @type {{ form: Vue.Ref<LocationFormConfig> }} */
            const { form } = toRefs(props);
            const store = useStore();

            /** @type {String} Form header. */
            const title = `Add Location`;

            const hierarchyComponent = ref(null);

            /** Provide the modal open/close API. */
            const { modal: standardsModal } = useModalToggle();

            const { isNARAEnabled, isNARADisabled } = useNARAFeature();

            const config = reactive({
                delay: 250,
                validationVisibility: 'blur',
            });

            const {
                cleanLocationDetails,
                dirtyLocationDetails,
                hierarchyModal,
                nodes,
            } = form.value.state;

            // PROPERTIES
            const {
                isBusy,
                isDeleting,
                isEditing,
                isEnabled,
                isRefreshing,
                isSaving,

                hasErrors,
                formErrors,
                hierarchyErrors,
            } = form.value.properties;

            // OPTIONS
            const { sections, timezoneOptions } = form.value.constants;
            const legacyTimezones = getLegacyTimezones();

            const { getOptions } = useNARAStandardOptions();
            const naraStandardOptions = getOptions();

            // HANDLERS
            const {
                onExit,
                onShowPhoto,
                onHidePhoto,
                onShowFloorplan,
                onHideFloorplan,
                onAddCancel,
                onCreate,
                onFileInput,
                onFileRemoveInput,
                onTextInput,
                onTimezoneInput,
                onNARAStandardInput,
            } = form.value.handlers;

            const onCreateLocation = async (state) => {
                config.validationVisibility = 'live';
                await hierarchyComponent.value.onApplyHierarchy();
                if (state.valid && hierarchyComponent.value.dirtyOutput.valid) {
                    onCreate();
                } else {
                    console.error('Form is not valid.');
                }
            };

            // METHODS
            const { getHierarchyTreeLevels, checkIfDirty } = form.value.methods;

            // DEBUG
            const { getDebugInfo } = useFormkitDebugger(
                ref('location-details-form'),
                cleanLocationDetails,
                dirtyLocationDetails
            );

            // // ==== COMPUTED PROPERTIES ====
            // const { isReadNARAStandardsModalOpen } = data;

            onMounted(() => {
                checkIfDirty();
            });

            return {
                isNARAEnabled,
                isNARADisabled,
                config,

                title,
                hierarchyModal,
                standardsModal,
                nodes,

                hierarchyComponent,

                cleanLocationDetails,
                dirtyLocationDetails,

                sections,
                timezoneOptions,
                legacyTimezones,
                naraStandardOptions,

                isBusy,
                isDeleting,
                isEditing,
                isEnabled,
                isRefreshing,
                isSaving,

                hasErrors,
                formErrors,
                hierarchyErrors,

                onShowPhoto,
                onHidePhoto,
                onShowFloorplan,
                onHideFloorplan,
                onAddCancel,
                onCreateLocation,
                onFileInput,
                onFileRemoveInput,
                onTextInput,
                onTimezoneInput,
                onNARAStandardInput,

                getHierarchyTreeLevels,
                checkIfDirty,

                getDebugInfo,
            };
        },
    });
</script>

<style scoped>
    .sn-hide-span span {
        display: none;
    }

    .sn-photo-link {
        color: #103355;
    }

    .sn-location-photo {
        min-width: 600px;
        min-height: 400px;
    }

    .sn-location-photo:before {
        content: ' ';
        position: absolute;
        top: 50%;
        left: 50%;
        border: 2px solid #092640;
        border-color: transparent #092640 transparent #092640;
        border-radius: 50%;
        animation: loader 1s linear infinite;
        z-index: -2;
    }

    @keyframes loader {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
</style>
