<template>
    <div
        class="flex flex-nowrap flex-row h-full w-full align-middle items-center justify-around"
    >
        <BaseTooltip
            target="#tooltip-target"
            text="Show Graph"
            :textSize="'text-md'"
            :width="115"
        >
            <template #icon>
                <VariantButton
                    variant="icon"
                    alt="Show Graph"
                    @click.prevent="
                        params.handleAnalysis($event, params.node.id)
                    "
                >
                    <ChartBarIcon
                        class="h-4 w-4"
                        aria-hidden="true"
                    />
                </VariantButton>
            </template>
        </BaseTooltip>
        <BaseTooltip
            target="#tooltip-target"
            text="Delete Weather Station"
            :textSize="'text-md'"
            :width="195"
        >
            <template #icon>
                <VariantButton
                    v-if="isNotDataAnalyst"
                    variant="danger-icon"
                    alt="Delete Weather Station"
                    @click.prevent="params.handleDelete($event, params.node.id)"
                >
                    <TrashIcon
                        class="h-4 w-4"
                        aria-hidden="true"
                    />
                </VariantButton>
            </template>
        </BaseTooltip>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import { ChartBarIcon, TrashIcon } from '@heroicons/vue/outline';
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import BaseTooltip from '@/components/tooltips/BaseTooltip.vue';

    // <!-- COMPOSABLES -->
    import { useDataAnalystGate } from '@/hooks/gates';

    // <!-- TYPES -->

    /**
     * @typedef {AgGrid.ICellRendererParams & { handleAnalysis: Function, handleDelete: Function }} WeatherStationTableIconsParams
     */

    // <!-- DEFINITION -->
    export default defineComponent({
        components: {
            ChartBarIcon,
            TrashIcon,
            VariantButton,
            BaseTooltip,
        },
        props: {
            params: {
                /** @type {import('vue').PropType<WeatherStationTableIconsParams>} */
                type: Object,
                required: true,
            },
        },
        setup(props, context) {
            const { isNotDataAnalyst } = useDataAnalystGate();
            return {
                isNotDataAnalyst,
            };
        },
    });
</script>

<style lang="scss">
    div.ag-theme-alpine.ag-cell {
        padding: 0px;
        margin: auto 1px;
    }
</style>
