<template>
    <FormSection
        class="pt-2"
        :grid="[
            'mt-1',
            'pt-2',
            'grid',
            'grid-cols-1',
            'gap-x-0',
            'sm:grid-cols-2',
        ]"
    >
        <div class="col-span-2">
            <FormKit
                id="hierarchy-preview"
                type="text"
                label="* Location Hierarchy"
                title="Preview the location hierarchy."
                name="path"
                :classes="{
                    outer: isRefreshing
                        ? 'px-4 sm:px-2 animate-pulse'
                        : 'px-4 sm:px-2',
                    inner: '$reset w-full border-none hover:cursor-not-allowed',
                    input: '$reset w-full rounded-lg border-gray-200',
                }"
                :disabled="true"
                :ignore="true"
                :errors="errors"
            >
                <template #input>
                    <div
                        class="w-full text-gray-600 rounded-lg border-1 border-gray-200 px-3 py-2 cursor-not-allowed whitespace-nowrap overflow-x-auto"
                    >
                        <span
                            v-if="
                                isInitialized &&
                                !!dirtyOutput &&
                                !!dirtyOutput?.path
                            "
                            >{{ dirtyOutput.path }}</span
                        >
                        <span v-else>{{
                            getHierarchyTreeLevels().join(' / ')
                        }}</span>
                    </div>
                </template>
            </FormKit>
        </div>
        <div class="col-span-1 sm:col-span-2">
            <LoadingWrapper :isLoading="!isInitialized || isRefreshing">
                <FormKit
                    id="hierarchy"
                    type="list"
                    #default="{ state }"
                    validation-visibility="live"
                >
                    <span
                        v-for="node in nodes"
                        :key="`${node.id}-${node.depth}`"
                        class="flex flex-col sm:flex-row justify-start"
                    >
                        <div class="flex-auto">
                            <FormKit
                                v-if="node.selector.active"
                                :id="node.selector.id"
                                type="select"
                                :name="node.selector.name"
                                :label="'* ' + node.selector.label"
                                :placeholder="node.selector.placeholder"
                                :value="node.selector.value"
                                @node="
                                    (event) =>
                                        node.selector.onFormKitNode(event)
                                "
                                @input="
                                    (value, event) =>
                                        node.selector.onFormKitInput(
                                            value,
                                            event
                                        )
                                "
                                :options="node.selector.options"
                                :disabled="node.selector.disabled"
                                :ignore="node.selector.ignore"
                                :preserve="node.selector.preserve"
                                :classes="node.selector.classes()"
                                validation="required"
                            />
                            <FormKit
                                v-else
                                :id="node.textbox.id"
                                type="text"
                                :name="node.textbox.name"
                                :label="node.textbox.label"
                                :placeholder="node.textbox.placeholder"
                                :value="node.textbox.value"
                                @node="
                                    (event) => node.textbox.onFormKitNode(event)
                                "
                                @input="
                                    (value, event) =>
                                        node.textbox.onFormKitInput(
                                            value,
                                            event
                                        )
                                "
                                :disabled="node.textbox.disabled"
                                :ignore="node.textbox.ignore"
                                :preserve="node.textbox.preserve"
                                :classes="node.textbox.classes()"
                                validation="required"
                            />
                        </div>
                        <div class="flex-none h-15 w-15 flex justify-center">
                            <button
                                v-if="node.forceTextMode"
                                class="flex flex-row items-center h-15 w-15 group cursor-not-allowed mb-2 sm:mb-0"
                                alt="You must create a new hierarchy at this level."
                                @click.prevent.capture.stop=""
                                :disabled="true"
                            >
                                <ArrowCircleUpIcon
                                    class="h-8 w-8 m-2 p-2 rounded bg-gray-50 text-gray-600 group-hover:bg-gray-200"
                                    aria-hidden="true"
                                />
                                <span
                                    class="block h-15 align-middle text-xs text-gray-600 sm:hidden mb-2 sm:mb-0"
                                    >You must create a new hierarchy
                                    level.</span
                                >
                            </button>
                            <button
                                v-else-if="node.selector.active"
                                class="flex flex-row items-center h-15 w-15 group disabled:cursor-not-allowed"
                                alt="Create New Hierarchy"
                                @click.prevent.capture.stop="
                                    node.preferTextMode = true
                                "
                            >
                                <PlusIcon
                                    class="h-8 w-8 m-2 p-2 rounded bg-gray-50 text-black group-hover:bg-blue-600 group-hover:text-white"
                                    aria-hidden="true"
                                />
                                <span
                                    class="block h-15 align-middle text-xs text-gray-600 sm:hidden"
                                    >Create new hierarchy.</span
                                >
                            </button>
                            <button
                                v-else
                                class="flex flex-row items-center h-15 w-15 group disabled:cursor-not-allowed mb-2 sm:mb-0"
                                alt="Select Existing Hierarchy"
                                @click.prevent.capture.stop="
                                    node.preferSelectMode = true
                                "
                                :disabled="node.forceTextMode"
                            >
                                <TrashIcon
                                    class="h-8 w-8 m-2 p-2 rounded bg-gray-50 text-black group-hover:bg-red-600 group-hover:text-white"
                                    aria-hidden="true"
                                />
                                <span
                                    class="block h-15 align-middle text-xs text-gray-600 sm:hidden"
                                    >Select existing hierarchy.</span
                                >
                            </button>
                        </div>
                    </span>
                    <div
                        class="w-full flex flex-row-reverse justify-center mt-1 pt-4 mb-2 h-15"
                    >
                        <ModalButton
                            theme="primary"
                            title="Apply path when selection is valid."
                            label="Apply Selected Path"
                            @click.prevent.capture.stop="onApplyHierarchy"
                            :disabled="
                                !(
                                    state.valid &&
                                    dirtyOutput.valid &&
                                    !tree.isRefreshing
                                )
                            "
                        />
                    </div>
                    <!-- DEBUG INFORMATION -->
                    <div
                        v-if="!!debug"
                        class="bg-gray-200 m-2 p-2 indent-0.5"
                    >
                        <pre
                            class="w-full text-xs subpixel-antialiased whitespace-pre-wrap break-words"
                            >{{ getDebugInfo({ state }) }}
                                </pre
                        >
                    </div>
                </FormKit>
            </LoadingWrapper>
        </div>
    </FormSection>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref, toRefs } from 'vue';
    import { HierarchyTree } from '@hooks/hierarchy/HierarchyTree';
    import hierarchies from '@/api/v1/accounts/hierarchies';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import FormSection from '@/components/forms/partials/FormSection.vue';
    import ModalButton from '@/components/modals/ModalButton.vue';
    import {
        PlusIcon,
        ArrowCircleUpIcon,
        TrashIcon,
    } from '@heroicons/vue/solid';

    // <!-- COMPOSABLES -->
    import { useFormkitDebugger } from '@/utils/FormKitDebugger';

    // <!-- TYPES -->

    /** @typedef {import('@/models/v1/locations/Location').LocationResource} LocationResource */
    import { LocationHierarchyPayload } from '@/models/v1/locations/LocationHierarchy';
    import { LocationFormConfig } from '~DataManager/hooks/useLocationForm';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AddLocationHierarchyFormSection',
        components: {
            LoadingWrapper,
            FormSection,
            ModalButton,
            PlusIcon,
            TrashIcon,
            ArrowCircleUpIcon,
        },
        props: {
            /** Form configuration. */
            form: {
                type: LocationFormConfig,
                required: true,
            },

            /** Debug mode. */
            debug: {
                type: Boolean,
                default: false, // HACK: Set to true to get debug mode content.
            },
        },
        setup(props, context) {
            // PROPS
            const { form } = toRefs(props);

            // STATE (TREE)

            /** @type {HierarchyTree} Tree context and API. */
            const tree = new HierarchyTree();

            /** Computed hierarchy tree nodes. */
            const nodes = tree.nodes;

            /** @type {Vue.Ref<String[]>} */
            const errors = ref([]);

            /** @type {Vue.ComputedRef<Boolean>} Initialization status tracker. */
            const isInitialized = tree.initialized;

            /** @type {Vue.ComputedRef<Boolean>} Refreshing status tracker. */
            const isRefreshing = tree.refreshing;

            /** @type {Vue.Ref<Boolean>} Saving status. */
            const isSaving = ref(false);

            /** @type {Vue.Ref<LocationResource>} Location resource reference. */
            const targetLocation = ref(
                form.value.state.dirtyLocationDetails.value.resource
            );

            // Get the source location, if one is present.
            const sourceLocation = HierarchyTree.useComputedSourceLocation(
                tree,
                targetLocation.value
            );

            /**
             * Get the clean input.
             */
            const cleanInput =
                HierarchyTree.useComputedCleanInput(sourceLocation);

            /**
             * Get the current output.
             */
            const dirtyOutput = HierarchyTree.useComputedDirtyOutput(tree);

            // Register init callback.
            tree.onInit(() => {
                if (!!sourceLocation.value.path) {
                    // Initialize the nodes.
                    for (const node of tree.nodes.value) {
                        const selected = tree.findHierarchyIndexResource(
                            sourceLocation.value.hierarchy[node.depth].id
                        );
                        if (!!selected) {
                            const parent = selected.parentId
                                ? tree.findHierarchyIndexResource(
                                      selected.parentId
                                  )
                                : null;
                            const value = String(selected.id);
                            const options = node.isRoot
                                ? tree.roots.value
                                : parent.children;
                            node.selector.init({
                                value,
                                options,
                            });
                        } else {
                            node.selector.init({ value: '', options: [] });
                        }
                        node.textbox.init({ value: '' });
                        node.preferSelectMode = true;
                    }
                } else {
                    // Initialize the nodes.
                    for (const node of tree.nodes.value) {
                        if (node.isRoot) {
                            const value = '';
                            const options = tree.roots.value;
                            node.selector.init({ value, options });
                        } else {
                            node.selector.init({ value: '', options: [] });
                        }
                        node.textbox.init({ value: '' });
                        node.preferSelectMode = true;
                    }
                }
                console.log(`Tree initialized.`);
            });

            // Register created callback.
            tree.onTreeCreated(() => {
                console.log(`Tree created.`);
            });

            // METHODS (EVENTS)

            /**
             * Initialize the hierarchy tree nodes.
             */
            const onInitHierarchyTree = async () => {
                // Initialize the tree nodes.
                await tree.init();
            };

            const onApplyHierarchy = async () => {
                try {
                    console.groupCollapsed(
                        `[apply::hierarchy] @ new ${new Date().toLocaleString()}`
                    );
                    isSaving.value = true;
                    errors.value = [];

                    // Assuming nodes are valid, we need to create all 'new' hierarchy resources.
                    if (!dirtyOutput.value.valid) {
                        throw new Error(
                            `Cannot save invalid state. Please confirm a valid option is selected for all hierarchies.`
                        );
                    }

                    // Determine if there are any new resources to create.
                    const hierarchy = dirtyOutput.value.hierarchy;
                    const exists = hierarchy.map(
                        (node) => node.id !== '' && node.id !== null
                    );
                    const allSelectedExist = exists.every(
                        (result) => result === true
                    );

                    // List to assign.
                    const list = { value: [] };

                    // Check if all selected resources exist.
                    if (allSelectedExist) {
                        console.log(`All selected resources already exist!`);
                        list.value = hierarchy;
                    } else {
                        // For each new hierarchy resource,
                        // - Get the parent node, if one is avialable.
                        // - Create the new hierarchy resource.
                        // - Replace the placeholder with the returned response.
                        // - Refresh the tree index to have the latest backend information.
                        // - Continue until all new hierarchy resources are created.
                        console.warn(
                            `Creating ${
                                exists.filter((result) => result === false)
                                    .length
                            } new hierarchy resource(s)...`
                        );

                        /** Account reference. */
                        const account = tree.account;

                        /** @type {({ id: string; name: string; depth: number; children: (string | number)[] })[]} Identifiers for persisted hierarchy nodes, in order of appearance. Used to select most recent already existing node. */
                        const persisted = [];

                        /** @type {Pick<LocationHierarchyPayload, 'name'|'parent_id'> & { children: string[] }} Partial object that will be updated before sending as the final request. */
                        const request = {
                            name: null,
                            parent_id: null,
                            children: [],
                        };

                        // Map existing hierarchy into the request object.
                        // - If the request is still null, we have not found the first new resource.
                        // - If the request is non-null, add the node as a child instead.
                        // - The parent node can be obtained using the persisted array.
                        for (const node of hierarchy) {
                            if (node.id !== null && node.id !== '') {
                                // If node is already persisted, add id to the array and continue.
                                persisted.push(node);
                                continue;
                            } else if (request.name === null) {
                                // If node is not persisted and this is the first new resource...
                                request.name = node.name;
                                if (persisted.length > 0) {
                                    // If this is a non-root, new resource, get the last existing id.
                                    const parentId = Number(
                                        persisted[persisted.length - 1].id
                                    );
                                    request.parent_id = parentId;
                                }
                                continue;
                            } else {
                                // If node is not persisted and this is an additional new resource...
                                request.children.push(node.name);
                            }
                        }

                        // Validate the request.
                        if (request.name === null) {
                            throw new Error(
                                `Cannot save invalid state. An error occurred while building the request: ${JSON.stringify(
                                    request
                                )}`
                            );
                        }

                        // Create the new hierarchy with the request.
                        const response = await hierarchies.createHierarchy(
                            account.value,
                            request
                        );

                        // Refresh the hierarchy index so we have most up to date hierarchies.
                        await tree.refreshHierarchyIndex();

                        // Get the descendants of the current node. If leaf is used, it will be the only element in the array.
                        const descendants = tree.getDescendantsAndSelf(
                            String(response.id)
                        );

                        // Get the last child.
                        const leaf = descendants[descendants.length - 1];

                        // Overwrite hierarchy for the assignment step.
                        list.value = tree.getAncestorsAndSelf(String(leaf.id));
                    }

                    // Once hierarchy resources are created.
                    // - Update assignment on the source location with the hierarchy resource value.
                    // - Does NOT persist the location, merely adds the created hierarchy reference to the source.
                    const previous = sourceLocation.value.leaf.id;
                    const next = list.value[list.value.length - 1].id;
                    if (previous === next) {
                        console.warn(
                            `Location hierarchy id [${next}] is already assigned to this location!`
                        );
                    } else {
                        console.log(`Updating location hierarchy.`);
                        const nodes = tree.getAncestorsAndSelf(next);
                        targetLocation.value.hierarchyId = Number(next);
                        targetLocation.value.hierarchy = nodes.slice(0);
                        console.dir({
                            previous,
                            next,
                            hierarchy: nodes,
                            updated: targetLocation.value,
                        });
                    }
                } catch (err) {
                    console.error(err);
                    errors.value = [err.message];
                } finally {
                    isSaving.value = false;
                    await onInitHierarchyTree();
                    console.groupEnd();
                }
            };

            // METHODS
            const { getHierarchyTreeLevels } = form.value.methods;

            // DEBUG
            const { inspectObjects, getDebugInfo } = useFormkitDebugger(
                ref('assign-location-hierarchy-form'),
                cleanInput,
                dirtyOutput
            );

            // LIFECYCLE
            onInitHierarchyTree();

            // EXPOSE
            return {
                tree,
                nodes,
                errors,
                sourceLocation,

                cleanInput,
                dirtyOutput,

                isInitialized,
                isRefreshing,
                isSaving,

                inspectObjects,
                getDebugInfo,

                getHierarchyTreeLevels,
                onApplyHierarchy,
            };
        },
    });
</script>
