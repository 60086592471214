// Composable used for managing Location form state.

// <!-- API -->
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import locations from '@/api/v1/accounts/locations';

// <!-- TYPES -->
import { Store } from 'vuex';
// ts-ignore
/** @typedef {globalThis.Account.Model} AccountResource */

// <!-- COMPOSABLES -->
import { ComposableConfig, ComposableModule } from '@/hooks/useComposable';

/**
 * @class
 * Location form expected props.
 */
export class LocationExportProps {
    /**
     * Save props to this object and give them expected types.
     * @param {Record<String, any>} props
     */
    constructor(props) {
        /** @type {() => Promise<any>} Async callback to execute when exiting the form. */
        this.onExport = props.onExport;

        /** @type {() => Promise<any>} Async callback to execute when an error occurs. */
        this.onError = props.onError;
    }
}

/**
 * @class
 * Location form configuration.
 * @template {Record<String, any>} [Props=any]
 * @template {V.EmitsOptions} [E=any]
 * @extends {ComposableConfig<Props, E, null, LocationExportState, LocationExportProperties, LocationExportHandlers, LocationExportMethods>}
 */
export class LocationExportConfig extends ComposableConfig {
    /**
     * Construct the configuration object.
     * @param {Props} props
     * @param {V.SetupContext<E>} config
     */
    constructor(props, config) {
        super(props, config);
        /** @type {Store} */
        this.store = useStore();
    }

    /**
     * Initialize the composable.
     * @returns {this}
     */
    initializeComposable() {
        this.isInitialized = false;
        // Order can be different for other composables.
        this.initializeState()
            .initializeProperties()
            .initializeMethods()
            .initializeHandlers();
        this.isInitialized = true;
        return this;
    }

    initializeState() {
        new LocationExportState(this);
        return this;
    }

    initializeProperties() {
        new LocationExportProperties(this);
        return this;
    }

    initializeHandlers() {
        new LocationExportHandlers(this);
        return this;
    }

    initializeMethods() {
        new LocationExportMethods(this);
        return this;
    }
}

/**
 * @class
 * Local form state.
 * @extends {ComposableModule<LocationExportConfig>}
 */
export class LocationExportState extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {LocationExportConfig} config Input configuration.
     */
    constructor(config) {
        super('state', config);
        /** @type {V.Ref<Boolean>} Determine if the form is fetching the location details from the remote. */
        this.exporting = ref(false);
        /** @type {V.Ref<Array<any>>} Collection of form errors, if any are present. */
        this.errors = ref([]);
    }
}

/**
 * @class
 * Local form state.
 * @extends {ComposableModule<LocationExportConfig>}
 */
class LocationExportProperties extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {LocationExportConfig} config Input configuration.
     */
    constructor(config) {
        super('properties', config);
        const { state } = config;
        /** @type {V.ComputedRef<Boolean>} Has errors? */
        this.hasErrors = computed(() => state.errors.value.length > 0);
        /** @type {V.ComputedRef<Boolean>} Is exporting? */
        this.isExporting = computed(() => state.exporting.value);
    }
}

/**
 * @class
 * Local form handlers.
 * @extends {ComposableModule<LocationExportConfig>}
 */
class LocationExportHandlers extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {LocationExportConfig} config Input configuration.
     */
    constructor(config) {
        super('handlers', config);
        const { state, props, methods } = config;
        /**
         * Export the specified location.
         * @param {{ id: Number, name?: String }} location
         */
        this.onExport = async (location) => {
            state.errors.value = [];
            try {
                // Attempts to export the location details.
                await methods.exportLocationResource(location);
                // If successful, run the callback.
                return props.onExport();
            } catch (err) {
                console.error(err);
                state.errors.value = [...state.errors.value, err];
                return props.onError();
            }
        };
    }
}

/**
 * @class
 * Local form methods.
 * @extends {ComposableModule<LocationExportConfig>}
 */
class LocationExportMethods extends ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {LocationExportConfig} config Input configuration.
     */
    constructor(config) {
        super('methods', config);
        const { store, state } = config;

        /**
         * Set exporting flag.
         * @param {Boolean} value
         */
        this.setExporting = (value) => {
            state.exporting.value = value;
        };

        /**
         * Get the current account from the Vuex store.
         * @returns {AccountResource}
         */
        const getCurrentAccount = () => {
            return store.state.accounts.account;
        };

        /**
         * Refresh location resource with information from the remote.
         * @param {{ id: Number, name?: String }} location Pointer.
         */
        this.exportLocationResource = async (location) => {
            try {
                state.exporting.value = true;
                console.groupCollapsed(
                    `[export::location::data] @ ${new Date().toLocaleDateString()}`
                );

                // Get the filename.
                const filename = `${
                    location?.name ?? 'UNKNOWN-LOCATION'
                }-export`;

                // Request up-to-date details.
                const account = getCurrentAccount();
                const request = location;
                const response = await locations.downloadLocation(
                    account,
                    request,
                    filename
                );
                console.dir({ export: response, filename });
            } catch (err) {
                console.error(err);
                throw err;
            } finally {
                // Close timer and console.
                state.exporting.value = false;
                console.groupEnd();
            }
        };
    }
}

/**
 * @template {V.EmitsOptions} E
 * Use Location form state.
 * @param {LocationExportProps} props Location form props.
 * @param {V.SetupContext<E>} context Setup context.
 * @returns {LocationExportConfig} Get the composable API.
 */
export const useLocationExport = (props, context) => {
    // Prepare the configuration.
    const config = new LocationExportConfig(props, context);

    // INIT
    config.initializeComposable();

    // EXPOSE
    return config;
};

// <!-- EXPORTS -->
export default {
    useLocationExport,
};
