// <!-- MODELS -->
import {
    Location,
    LocationPayload,
    LocationResource,
} from '@/models/v1/locations/Location';

// <!-- TYPES -->
import { useTimezoneIfValid } from './useTimezoneOptions';

// <!-- UTILITIES -->
import omit from 'just-omit';
import pick from 'just-pick';
import { formatISO } from 'date-fns';
import { DateTimeISO } from '@/utils/datetime';
import { LocationHierarchyResource } from '@/models/v1/locations/LocationHierarchy';
import { NARAStandard } from '@/models/v1/standards/NARAStandard';

/**
 * Helper class used for tracking location details.
 * @class
 */
export class LocationDetails {
    /** @type {LocationResource} Internal resource that feeds into the FormKit data. */
    resource = null;

    /** @type {{ remove: Boolean, url?: String, value?: { name: String, file: File }[] }} */
    photo = {
        remove: false,
        url: null,
        value: [],
    };

    /** @type {{ remove: Boolean, url?: String, value?: { name: String, file: File }[] }} */
    floorplan = {
        remove: false,
        url: null,
        value: [],
    };

    /** @type {LocationHierarchyResource[]} Array of selected location hierarchy node ids. Depth determined by array index. */
    hierarchy = new Array(5);

    /** @type {String} String representing the selected option value for the nara standard. */
    standard = 'placeholder';

    /**
     * Create a deep clone of an existing {@link LocationDetails} instance.
     * @param {LocationDetails} details
     */
    static clone(details) {
        const exists = !!details;
        const resource = exists ? details.resource : {};
        const removePhoto = exists ? details.photo.remove : false;
        const removeFloorplan = exists ? details.floorplan.remove : false;
        const instance = new LocationDetails(
            resource,
            removePhoto,
            removeFloorplan
        );
        instance.hierarchy = details.hierarchy.slice(0, 5);
        instance.resource.hierarchy = details.resource.hierarchy.slice(0);
        instance.resource.hierarchyId = details.resource.hierarchyId;
        return instance;
    }

    /**
     * Create a new {@link LocationDetails} instance using the provided resource.
     * @param {LocationResource} resource
     */
    static create(resource) {
        const removePhoto = false;
        const removeFloorplan = false;
        const instance = new LocationDetails(
            resource,
            removePhoto,
            removeFloorplan
        );
        return instance;
    }

    /**
     * Construct location details reference.
     * @param {Partial<import('@/types').ExcludeMethods<LocationResource>>} [resource]
     * @param {Boolean} [removePhoto]
     * @param {Boolean} [removeFloorplan]
     */
    constructor(resource = {}, removePhoto = false, removeFloorplan = false) {
        // Check if resource passed actually exists.
        const exists = !!resource;

        // Perform copy of the resource.
        this.resource = new LocationResource(resource ?? {});

        const hasPhotoURL =
            exists && this.resource.photo && this.resource.photo !== 'false';

        const hasFloorplanURL =
            exists &&
            this.resource.floorplan &&
            this.resource.floorplan !== 'false';

        // Initialize valid timezone.
        this.resource.timezone =
            useTimezoneIfValid(this.resource.timezone) ?? 'placeholder';

        // Initialize valid photo field.
        this.photo = {
            remove: exists ? removePhoto : false,
            url: hasPhotoURL ? resource.photo : null,
            value: [],
        };

        // Initialize valid floorplan field.
        this.floorplan = {
            remove: exists ? removeFloorplan : false,
            url: hasFloorplanURL ? resource.floorplan : null,
            value: [],
        };

        // Initialize the hierarchy.
        this.hierarchy = this.hasHierarchy
            ? this.resource?.hierarchy ?? []
            : [];

        // Initialize the standard option.
        this.standard = this.hasNARAStandard
            ? String(this.resource?.naraStandard?.id ?? 'placeholder')
            : 'placeholder';
    }

    // <!-- PROPERTIES -->

    /**
     * Determine if the resource exists.
     */
    get exists() {
        return !!this.resource;
    }

    /**
     * Determine if date range is present.
     */
    get hasDateRange() {
        return this.exists && this.resource.minDate && this.resource.maxDate;
    }

    /**
     * Determine if date field is present.
     */
    get hasMinDate() {
        return this.exists && !!this.resource.minDate;
    }

    /**
     * Determine if date field is present.
     */
    get hasMaxDate() {
        return this.exists && !!this.resource.maxDate;
    }

    get hasHierarchy() {
        return this.exists && !!this.resource.hierarchyId;
    }

    /**
     * Determine if a hierarchy path is available.
     */
    get hasPath() {
        return this.exists && this.hasHierarchy && !!this.resource.path;
    }

    /**
     * Determine if a display name is available.
     */
    get hasLabel() {
        return this.exists && !!this.resource.label;
    }

    /**
     * Determine if the NARA Standard.
     */
    get hasNARAStandard() {
        return this.exists && !!this.resource.naraStandard;
    }

    /**
     * Get the formatted date range.
     */
    get dateRange() {
        if (this.hasDateRange) {
            const dateStart = formatISO(
                DateTimeISO.parse(this.resource.minDate),
                {
                    format: 'extended',
                    representation: 'date',
                }
            );
            const dateEnd = formatISO(
                DateTimeISO.parse(this.resource.maxDate),
                {
                    format: 'extended',
                    representation: 'date',
                }
            );
            return [dateStart, dateEnd].join(' : ');
        } else {
            return null;
        }
    }

    /**
     * Get the resource hierarchy path.
     */
    get path() {
        return this.hasPath ? this.resource.path : null;
    }

    /**
     * Get the resource hierarchy display name.
     */
    get label() {
        return this.hasLabel
            ? this.resource.label
            : '<Unassigned>/<Untitled Location>';
    }

    /**
     * Determine if an existing photo URL is present.
     */
    get hasPhotoURL() {
        return !!this.photo && !!this.photo.url && this.photo.url !== 'false';
    }

    /**
     * Determine if the user has selected a file to upload for the specified field.
     */
    get hasPhotoFileSelected() {
        return (
            !!this.photo && !!this.photo.value && this.photo.value.length > 0
        );
    }

    /**
     * Determine if photo file should be uploaded. Cannot upload if specifying removal of file.
     */
    get canUploadPhoto() {
        return this.hasPhotoFileSelected && !this.photo.remove;
    }

    /**
     * Determine if an existing floorplan URL is present.
     */
    get hasFloorplanURL() {
        return (
            !!this.floorplan &&
            !!this.floorplan.url &&
            this.floorplan.url !== 'false'
        );
    }

    /**
     * Determine if the user has selected a file to upload for the specified field.
     */
    get hasFloorplanFileSelected() {
        return (
            !!this.floorplan &&
            !!this.floorplan.value &&
            this.floorplan.value.length > 0
        );
    }

    /**
     * Determine if floorplan file should be uploaded. Cannot upload if specifying removal of file.
     */
    get canUploadFloorplan() {
        return this.hasFloorplanFileSelected && !this.floorplan.remove;
    }

    /**
     * Determine if the standard can be uploaded.
     */
    get canUploadStandard() {
        const exists = !!this.standard;
        const isValid =
            exists &&
            this.standard !== '' &&
            this.standard !== 'placeholder' &&
            !Number.isNaN(parseInt(this.standard, 10));
        return isValid;
    }

    /**
     * Create the sanitized request for upload.
     */
    getSanitizedRequest() {
        /** @type {LocationPayload} Location payload. */
        const payload = new Location({ resource: this.resource }).toPayload();

        /** Properties to deny changes for. */
        const GuardedProps = /** @type {const} */ ([
            'id',
            'min_date',
            'max_date',
            'serial',
            'photo',
            'floorplan',
            'entries',
            'parseModel',
            'hierarchy',
            'nara_standard',
            'location_hierarchy_id',
        ]);

        /** Properties to choose updates for. */
        const SelectedProps = /** @type {const} */ ([
            'name',
            'data_logger_manufacturer',
            'data_logger_placement',
            'data_logger_serial_number',
            'collections_contact',
            'facilities_contact',
            'department_or_division',
            'air_source',
            'bms_id',
            'zone',
            'timezone',
        ]);

        /** @type {Pick<Omit<LocationPayload, GuardedProps[Number]>, SelectedProps[Number]>} */
        const sanitized = pick(
            omit(payload, ...GuardedProps),
            ...SelectedProps
        );

        /** @type {Partial<typeof sanitized> & { location_hierarchy_id: (Number | ''), nara_standards_id?: (Number | ''), remove_photo?: (1 | 0), remove_floorplan?: (1 | 0), photo?: { name: String, file: File }, floorplan?: { name: String, file: File }}} */
        const request = {
            ...sanitized,
            location_hierarchy_id: this.hasHierarchy
                ? this?.resource?.hierarchyId
                : '',
            nara_standards_id: this.canUploadStandard
                ? parseInt(this.standard, 10)
                : '',
            remove_photo: this.photo.remove ? 1 : 0,
            photo: this.canUploadPhoto ? this.photo.value[0] : null,
            remove_floorplan: this.floorplan.remove ? 1 : 0,
            floorplan: this.canUploadFloorplan ? this.floorplan.value[0] : null,
        };

        // Return sanitized output.
        return request;
    }

    /**
     * Create a FormData object from the sanitized request.
     * @returns {FormData}
     */
    getUpdateFormRequest() {
        // Remove non-fillable fields if present.

        // Get the sanitized request.
        const sanitized = this.getSanitizedRequest();

        // Get the fillable properties.
        const fillable = omit(sanitized, ['photo', 'floorplan']);

        // Prepare form data.
        try {
            console.group(
                `[update::location::request] @ ${new Date().toLocaleDateString()}`
            );
            console.log('Creating form data object.');

            // Append fillable properities.
            const request = new FormData();
            for (const key of Object.keys(fillable)) {
                if (fillable[key] !== undefined) {
                    const field = String(key);
                    const value = String(fillable[key]);
                    request.append(key, value);
                    console.log(`Appended { "${field}": "${value}" }`);
                } else {
                    const field = String(key);
                    const value = '';
                    request.append(key, value);
                    console.log(
                        `Appended { "${field}": "${value}" }. Value was undefined.`
                    );
                }
            }

            // Add photo.
            if (!!sanitized.photo && !!sanitized.photo.file) {
                // TODO: Check file size constraints.
                request.append('photo', sanitized.photo.file);
                console.log(
                    `Appended { "photo": ${JSON.stringify(
                        sanitized.photo.file
                    )} }`
                );
            } else {
                console.log(`No photo to upload.`);
            }

            // Add floorplan.
            if (!!sanitized.floorplan && !!sanitized.floorplan.file) {
                // TODO: Check file size constraints.
                request.append('floorplan', sanitized.floorplan.file);
                console.log(
                    `Appended { "floorplan": ${JSON.stringify(
                        sanitized.floorplan.file
                    )} }`
                );
            } else {
                console.log(`No floorplan to upload.`);
            }
            console.log(...request);
            return request;
        } catch (err) {
            console.error(err);
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Create a FormData object from the sanitized request.
     * @returns {FormData}
     */
    getCreateFormRequest() {
        // Remove non-fillable fields if present.

        // Get the sanitized request.
        const sanitized = this.getSanitizedRequest();

        // Get the fillable properties.
        const fillable = omit(sanitized, [
            'photo',
            'floorplan',
            'remove_photo',
            'remove_floorplan',
            'nara_standards_id',
        ]);

        // Prepare form data.
        try {
            console.group(
                `[create::location::request] @ ${new Date().toLocaleDateString()}`
            );
            console.log('Creating form data object.');

            // Append fillable properities.
            const request = new FormData();
            for (const key of Object.keys(fillable)) {
                if (fillable[key] !== undefined) {
                    const field = String(key);
                    const value = String(fillable[key]);
                    request.append(key, value);
                    console.log(`Appended { "${field}": "${value}" }`);
                } else {
                    console.log(`Skipping... Value for ${key} is undefined.`);
                }
            }

            // Add photo.
            if (!!sanitized.photo && !!sanitized.photo.file) {
                // TODO: Check file size constraints.
                request.append('photo', sanitized.photo.file);
                console.log(
                    `Appended { "photo": ${JSON.stringify(
                        sanitized.photo.file
                    )} }`
                );
            } else {
                console.log(`No photo to upload.`);
            }

            // Add floorplan.
            if (!!sanitized.floorplan && !!sanitized.floorplan.file) {
                // TODO: Check file size constraints.
                request.append('floorplan', sanitized.floorplan.file);
                console.log(
                    `Appended { "floorplan": ${JSON.stringify(
                        sanitized.floorplan.file
                    )} }`
                );
            } else {
                console.log(`No floorplan to upload.`);
            }

            // Add hierarchy.
            if (!!sanitized.location_hierarchy_id) {
                const isHierarchyIDNull = Number.isNaN(
                    parseInt(String(sanitized?.location_hierarchy_id ?? ''), 10)
                );
                request.append(
                    'location_hierarchy_id',
                    isHierarchyIDNull
                        ? ''
                        : String(sanitized?.location_hierarchy_id ?? '')
                );
            }

            // Add standard.
            if (!!sanitized.nara_standards_id) {
                const isStandardNull = Number.isNaN(
                    parseInt(String(sanitized?.nara_standards_id ?? ''), 10)
                );
                request.append(
                    'nara_standards_id',
                    isStandardNull
                        ? ''
                        : String(sanitized?.nara_standards_id ?? '')
                );
            }

            return request;
        } catch (err) {
            console.error(err);
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Update the timezone resource.
     *
     * @param {String} value Timezone value.
     */
    onTimezoneInput(value) {
        try {
            console.groupCollapsed(
                `[update::timezone] - at ${new Date().toLocaleDateString()}`
            );
            const previous = this.resource.timezone;
            const next = useTimezoneIfValid(value);
            const result = { previous, next };
            console.dir(result);

            // Assign update.
            const isNull = next === null;
            this.resource.timezone = isNull ? null : next;

            // Return resulting value.
            return result;
        } catch (err) {
            console.error(err);
            this.onTimezoneInput(null);
        } finally {
            console.groupEnd();
        }
    }

    /**
     * Update the NARA Standard resource.
     *
     * @param {String} value NARA Standard value.
     */
    onNARAStandardInput(value) {
        try {
            console.groupCollapsed(
                `[update::nara-standard] - at ${new Date().toLocaleDateString()}`
            );
            const previous = this.standard;
            const next = value;
            const result = { previous, next };
            console.dir(result);

            // Assign update.
            const isNull = next === null || Number.isNaN(parseInt(next, 10));
            this.standard = isNull ? 'placeholder' : next;

            // Return resulting value.
            return result;
        } catch (err) {
            console.error(err);
            this.onNARAStandardInput(null);
        } finally {
            console.groupEnd();
        }
    }
}

/**
 * Construct a location details instance.
 * @param {{ config?: { resource: import('@/types').ExcludeMethods<LocationResource>, removePhoto?: Boolean, removeFloorplan?: Boolean }, details?: LocationDetails }} [props]
 * @returns {LocationDetails}
 */
export const useLocationDetails = (props) => {
    const { config, details } = props ?? {};
    if (!!details) {
        return LocationDetails.clone(details);
    } else {
        const { resource, removePhoto, removeFloorplan } = config;
        return new LocationDetails(resource, removePhoto, removeFloorplan);
    }
};

// <!-- EXPORTS -->
export default {
    LocationDetails,
    useLocationDetails,
};
