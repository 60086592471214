<template>
    <div class="pt-5">
        <div class="flex justify-end space-x-4">
            <ModalButton
                theme="white"
                label="Cancel"
                @click.stop="onCancel"
            />
            <ModalButton
                v-if="!hideSave"
                theme="primary"
                label="Save"
                @click.stop="onSave"
                :disabled="!allowSave"
            />
        </div>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';

    // <!-- COMPONENTS -->
    import ModalButton from '@/components/modals/ModalButton.vue';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FormSubmitCancel',
        props: {
            onSave: Function,
            onCancel: Function,
            allowSave: Boolean,
            hideSave: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            ModalButton,
        },
    });
</script>
