<template>
    <LoadingWrapper :isLoading="!isInitialized">
        <div>
            <FormKit
                type="form"
                v-model="dirtyWeatherStation"
                #default="context"
                :actions="false"
                :config="{ 'validation-visibility': 'blur' }"
            >
                <div>
                    <FormKit
                        type="select"
                        label="* Country"
                        name="country"
                        :options="countries"
                        placeholder="Select a country"
                        :classes="{
                            input: 'disabled:px-3',
                        }"
                        validation="required"
                        validation-label="Country"
                    />
                </div>

                <div>
                    <FormKit
                        type="select"
                        label="* State"
                        name="state"
                        @change="onStateChange"
                        :options="stateList"
                        :disabled="stateListLoading"
                        placeholder="Select a state"
                        :classes="{
                            outer: stateListLoading ? 'animate-pulse' : '',
                            input: 'disabled:px-3',
                        }"
                        validation="required"
                        validation-label="State"
                    />
                </div>
                <div>
                    <FormKit
                        type="select"
                        label="* Weather Station"
                        name="weatherStation"
                        placeholder="Select a weather station"
                        :options="weatherStationList"
                        :disabled="
                            weatherStationList.length === 0 ||
                            weatherStationListLoading
                        "
                        :classes="{
                            outer: weatherStationListLoading
                                ? 'animate-pulse'
                                : '',
                            input: 'disabled:px-3',
                        }"
                        validation="required"
                        validation-label="Weather Station"
                    />
                </div>

                <p class="text-gray-400 text-sm">
                    * indicates a required field
                </p>
                <FormSubmitCancel
                    :allowSave="context.state.valid"
                    :onSave="onClickSave"
                    :onCancel="onClickCancel"
                />
            </FormKit>
        </div>
    </LoadingWrapper>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref, onMounted } from 'vue';
    import {
        fetchWeatherStates,
        fetchWeatherStationsByState,
    } from '@/api/v1/weather/index.js';

    // <!-- ENUMS -->
    import { CountryName } from '@/enums';

    // <!-- COMPONENTS -->
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import FormSubmitCancel from '@/components/FormSubmitCancel.vue';

    // <!-- UTILITIES -->
    import { capitalFormatter } from '@/utils/CapitalFormatter';

    // <!-- COMPOSABLES -->
    import { countries } from '@/hooks/useCountries.js';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'WeatherStationFields',
        components: {
            LoadingWrapper,
            FormSubmitCancel,
        },

        props: {
            onSave: Function,
            onCancel: Function,
        },

        setup(props, context) {
            const dirtyWeatherStation = ref({
                country: 'United States',
                state: '',
                weatherStation: '',
            });
            const stateList = ref([]);
            const weatherStationList = ref([]);
            const stateListLoading = ref(true);
            const weatherStationListLoading = ref(false);
            const isInitialized = ref(false);

            const initializeForm = async () => {
                isInitialized.value = false;
                dirtyWeatherStation.value = {
                    country: 'United States',
                    state: '',
                    weatherStation: '',
                };
                await fetchStates();
                isInitialized.value = true;
            };

            const onResetCountry = () => {
                dirtyWeatherStation.value.country = '';
                onResetState();
            };

            const onResetState = () => {
                dirtyWeatherStation.value.state = '';
                onResetWeatherStation();
            };

            const onResetWeatherStation = () => {
                dirtyWeatherStation.value.weatherStation = '';
            };

            const onClickSave = () => {
                props.onSave(dirtyWeatherStation.value);
            };
            const onClickCancel = () => {
                props.onCancel();
            };

            const fetchStates = async () => {
                try {
                    stateListLoading.value = true;
                    const response = await fetchWeatherStates();
                    stateList.value = response.map((state) => {
                        return {
                            label: state.name,
                            value: state.id,
                        };
                    });
                } catch {
                    console.log('Error loading weather states');
                } finally {
                    stateListLoading.value = false;
                }
            };

            const onStateChange = async (event) => {
                try {
                    weatherStationListLoading.value = true;
                    dirtyWeatherStation.value.weatherStation = ''; // Reset weather station.
                    const response = await fetchWeatherStationsByState({
                        id: event.target.value,
                    });
                    weatherStationList.value = response.map((station) => {
                        return {
                            label: capitalFormatter(station.name),
                            value: station.id,
                        };
                    });
                } catch (e) {
                    console.log('Error loading weather stations', e);
                } finally {
                    weatherStationListLoading.value = false;
                }
            };

            // Use the United States as the countries array.
            const useUnitedStates = () =>
                countries.filter(
                    (country) => country === CountryName.ByISO3.USA
                );

            onMounted(async () => {
                await initializeForm();
            });

            return {
                dirtyWeatherStation,
                onClickSave,
                onClickCancel,
                onStateChange,
                stateList,
                weatherStationList,
                countries: useUnitedStates(),
                stateListLoading,
                weatherStationListLoading,
                isInitialized,
            };
        },
    });
</script>

<style lang="scss">
    .user-access-radio-list {
        li {
            display: inline-block;
        }
    }
</style>
