<template>
    <a
        v-if="isLinkEnabled"
        class="sn-filelink cursor-pointer underline"
        :onClick="onLinkClick"
    >
        {{ params.value || '--' }}
    </a>
    <a
        v-else
        class="sn-filelink cursor-not-allowed"
    >
        {{ params.value || '--' }}
    </a>
</template>
<script>
    // <!-- API -->
    import { defineComponent } from 'vue';
    import { computedEager } from '@vueuse/shared';

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'FileLink',
        props: {
            params: {
                /** @type {import('vue').PropType<AgGrid.ICellRendererParams & { isLinkEnabled: (data: any) => boolean, handleLinkClick: (data: any) => void }>} */
                type: Object,
                required: true,
            },
        },
        setup(props) {
            /* Functions */
            const isLinkEnabled = computedEager(() => {
                const rowData = props.params.data;
                const enabled = props.params.isLinkEnabled(rowData);
                return enabled === true;
            });

            const onLinkClick = () => {
                props.params.handleLinkClick(props.params.data);
            };

            return {
                isLinkEnabled,
                onLinkClick,
            };
        },
    });
</script>

<style>
    .sn-filelink {
        color: #103355;
    }
</style>
