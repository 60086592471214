<template>
    <ConfirmDeleteModal
        title="Delete Dataset Batch"
        confirmationText="Are you sure you want to delete this dataset batch?"
        :open="confirmDeleteModalOpen"
        @delete="onDeleteDataset"
        @cancel="onConfirmDeleteCancel"
        @close="onConfirmDeleteCancel"
    />
    <!-- <button @click="forceRefresh">TEST REFRESH</button> -->
    <LoadingWrapper :isLoading="isLoading || isBusy">
        <SectionHeader class="mb-6">Upload History</SectionHeader>
        <div class="text-sm text-gray-400 py-1">
            Dates in table are shown in {{ displayTimezone }}.
        </div>
        <AgGridVue
            class="ag-theme-alpine"
            :pagination="true"
            :paginationPageSize="25"
            :columnDefs="colDefs"
            :rowData="state.datasetBatches"
            domLayout="autoHeight"
            :defaultColDef="defaultColDef"
            :getRowNodeId="useDatasetBatchIdAsId"
            @grid-ready="onGridReady"
            @column-resized="onColumnResized"
            overlayNoRowsTemplate="No datasets available."
        ></AgGridVue>
    </LoadingWrapper>
</template>

<script>
    // <!-- API -->
    import {
        defineComponent,
        onMounted,
        reactive,
        ref,
        toRefs,
        watchEffect,
        computed,
    } from 'vue';
    import { promiseTimeout } from '@vueuse/shared';

    // <!-- COMPONENTS -->
    import { AgGridVue } from 'ag-grid-vue3';
    import LoadingWrapper from '@/components/LoadingWrapper.vue';
    import SectionHeader from '@/components/SectionHeader.vue';
    import ConfirmDeleteModal from '@/components/ConfirmDeleteModal.vue';
    import DatasetBatchesTableIcons from '~DataManager/components/cell/DatasetBatchesTableIcons.vue';
    import FileLink from '~DataManager/components/cell/FileLink.vue';

    // <!-- UTILITIES -->
    import isNil from 'lodash-es/isNil';
    import { saveAs } from 'file-saver';
    import { DateTimeISO } from '@/utils/datetime';
    import { formatISO } from 'date-fns';
    import { Maybe } from 'true-myth/dist/maybe';

    // <!-- COMPOSABLES -->
    import { LocationFormConfig } from '~DataManager/hooks/useLocationForm';
    import { useAxios as axios } from '@plugins/axios';
    import useAgGridVue from '@/hooks/useAgGridVue';

    // <!-- TYPES -->

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DatasetBatchesTable',
        props: {
            /** Form configuration. */
            form: {
                type: LocationFormConfig,
                required: true,
            },

            isBusy: {
                type: Boolean,
                default: false,
            },
        },

        components: {
            ConfirmDeleteModal,
            LoadingWrapper,
            SectionHeader,
            AgGridVue,
        },

        setup(props) {
            const { form, isBusy } = toRefs(props);

            // STORE
            const store = form.value.cache.api.store;

            // PROPS (FORM)
            const currentLocation = form.value.properties.sourceLocation;

            /** @type {Vue.ComputedRef<Account.Model>} */
            const currentAccount = computed(
                () => store?.state?.accounts?.account
            );

            // STATE
            const state = reactive({
                datasetBatches: [],
                datasetBatchIdToDelete: null,
            });

            const displayTimezone = ref('UTC');

            // METHODS
            const getAccount = () => store.state.accounts.account;
            const onRefresh = form.value.handlers.onRefresh;

            /* Data */
            let isLoading = ref(false);
            let confirmDeleteModalOpen = ref(false);

            /**
             * @type {AgGrid.ValueFormatterFunc}
             * Format the ISO string into just its date component.
             */
            const formatDate = (params) => {
                // Get the value.
                const date = Maybe.of(params.value).map((dt) =>
                    DateTimeISO.parse(dt)
                );
                // Conditionally execute based on if row was found.
                const formatted = date.match({
                    Just: (dt) => {
                        return dt.toLocaleDateString('en-ca', {
                            timeZone: displayTimezone.value,
                        });
                    },
                    Nothing: () => 'No date provided.',
                });
                // Return the value.
                return formatted;
            };

            const colDefs = [
                {
                    headerName: '',
                    field: 'id',
                    cellRendererFramework: DatasetBatchesTableIcons,
                    lockPosition: true,
                    maxWidth: 100,
                    filter: false,
                    floatingFilter: false,
                    cellRendererParams: {
                        isExportable: (id) => {
                            return checkDatasetBatchIsDownloadableById(id);
                        },
                        handleExport: (event, id) => {
                            handleDatasetBatchExport(id);
                        },
                        handleDelete: (event, id) => {
                            handleDeleteDatasetBatch(id);
                        },
                    },
                },
                {
                    headerName: 'File Name',
                    field: 'filename',
                    cellRendererFramework: FileLink,
                    filter: false,
                    floatingFilter: false,
                    cellRendererParams: {
                        isLinkEnabled: (rowData) => {
                            return checkDatasetBatchIsDownloadable(rowData);
                        },
                        handleLinkClick: (rowData) => {
                            onDatasetBatchFileDownload(
                                rowData.id,
                                rowData.filename
                            );
                        },
                    },
                },

                {
                    headerName: 'Uploaded',
                    field: 'created_at',
                    valueFormatter: formatDate,
                    filter: false,
                    floatingFilter: false,
                    maxWidth: 150,
                    sort: 'desc',
                },
                {
                    headerName: 'Data Start',
                    field: 'data_start',
                    valueFormatter: formatDate,
                    filter: false,
                    floatingFilter: false,
                    maxWidth: 150,
                },
                {
                    headerName: 'Data End',
                    field: 'data_end',
                    valueFormatter: formatDate,
                    filter: false,
                    floatingFilter: false,
                    maxWidth: 150,
                },
                {
                    headerName: '# of Data Points',
                    field: 'number_of_points',
                    /** @param {AgGrid.ICellRendererParams} params */
                    valueGetter: (params) => {
                        const points = params.data?.number_of_points;
                        const exists = !!points;
                        return exists ? points : 'Not Ingested';
                    },
                    filter: false,
                    floatingFilter: false,
                    maxWidth: 150,
                },
            ];

            /* Functions */
            const onDatasetBatchFileDownload = async (id, filename) => {
                try {
                    // TODO: Refactor into API.
                    const account = getAccount().id;
                    const location = currentLocation.value.id;
                    const response = await axios().get(
                        `accounts/${account}/locations/${location}/datasets/${id}/file`,
                        { responseType: 'blob' }
                    );
                    saveAs(response.data, filename);
                } catch (err) {
                    console.warn('Error downloading dataset batch file');
                }
            };

            // Get the dataset batches.
            const getDatasetBatches = async () => {
                isLoading.value = true;
                // TODO: Refactor into API.
                const account = getAccount().id;
                const location = currentLocation.value.id;
                const response = await axios().get(
                    // Data returned from API does not match expected name
                    `accounts/${account}/locations/${location}/datasets`
                );
                state.datasetBatches = response.data.data.map((item) => {
                    const target = Object.assign(item);
                    const isLegacyBatch = item.filetype == 'import';
                    target.enable_download = !isLegacyBatch;
                    return target;
                });
                isLoading.value = false;
            };

            const { onGridReady, onColumnResized, defaultColDef } =
                useAgGridVue();
            const useDatasetBatchIdAsId = (data) => {
                return data.id;
            };

            const handleDeleteDatasetBatch = async (datasetBatchId) => {
                confirmDeleteModalOpen.value = true;
                state.datasetBatchIdToDelete = datasetBatchId;
            };

            const checkDatasetBatchIsDownloadableById = (datasetBatchId) => {
                const targetDatasetBatch = state.datasetBatches.find(
                    (datasetBatch) => {
                        return datasetBatch.id == datasetBatchId;
                    }
                );
                return checkDatasetBatchIsDownloadable(targetDatasetBatch);
            };

            const getDatasetBatchFilenameById = (datasetBatchId) => {
                const targetDatasetBatch = state.datasetBatches.find(
                    (datasetBatch) => {
                        return datasetBatch.id == datasetBatchId;
                    }
                );
                return targetDatasetBatch.filename
                    ? targetDatasetBatch.filename
                    : 'UNKNOWN';
            };

            const checkDatasetBatchIsDownloadable = (targetDatasetBatch) => {
                return targetDatasetBatch?.enable_download === true;
            };

            const handleDatasetBatchExport = async (datasetBatchId) => {
                // TODO: Refactor API.
                const account = getAccount().id;
                const location = currentLocation.value.id;
                const response = await axios().get(
                    `accounts/${account}/locations/${location}/datasets/${datasetBatchId}/file`,
                    { responseType: 'blob' }
                );
                const filename = getDatasetBatchFilenameById(datasetBatchId);
                saveAs(response.data, filename);
            };

            const onDeleteDataset = async () => {
                confirmDeleteModalOpen.value = false;
                isLoading.value = true;
                // TODO: Refactor API.
                const account = getAccount().id;
                const location = currentLocation.value.id;
                const response = await axios().delete(
                    `accounts/${account}/locations/${location}/datasets/${state.datasetBatchIdToDelete}`
                );
                if (response.status == 500) {
                    isLoading.value = false;
                    console.error('Failed to delete dataset batch');
                }
                isLoading.value = false;
                await getDatasetBatches();
                await promiseTimeout(5000);
                await onRefresh();
            };

            const onConfirmDeleteCancel = () => {
                confirmDeleteModalOpen.value = false;
            };

            const forceRefresh = () => {
                onRefresh();
            };

            /* LifeCycle */
            onMounted(async () => {
                await getDatasetBatches();

                watchEffect(() => {
                    displayTimezone.value =
                        currentAccount.value?.timezone ?? 'UTC';
                });
            });

            return {
                state,
                displayTimezone,
                useDatasetBatchIdAsId,
                getDatasetBatches,
                colDefs,
                onGridReady,
                onColumnResized,
                defaultColDef,
                handleDeleteDatasetBatch,
                isLoading,
                confirmDeleteModalOpen,
                onDeleteDataset,
                onConfirmDeleteCancel,
                forceRefresh,
            };
        },
    });
</script>

<style lang="scss" scoped></style>
