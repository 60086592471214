<template>
    <Panel>
        <template #header>
            <div class="flex flex-row justify-between">
                <div class="text-2xl text-black font-bold">
                    {{ title }}
                </div>
            </div>
        </template>
        <template #default>
            <transition
                :appear="true"
                enter-active-class="transition ease-out duration-200"
                enter-from-class="transform opacity-0"
                enter-to-class="transform opacity-100"
                leave-active-class="transition ease-in duration-100"
                leave-from-class="transform opacity-100"
                leave-to-class="transform opacity-0"
            >
                <WeatherStationFields
                    :onSave="onAddSave"
                    :onCancel="onAddCancel"
                />
            </transition>
        </template>
    </Panel>
</template>

<script>
    // <!-- API -->
    import { defineComponent, ref } from 'vue';

    // <!-- COMPONENTS -->
    import Panel from '@/components/Panel.vue';
    import WeatherStationFields from '~DataManager/components/inputs/WeatherStationFields.vue';

    // <!-- TYPES -->

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'AddWeatherStationForm',
        components: {
            Panel,
            WeatherStationFields,
        },
        props: {
            onCancel: Function,
            onSave: Function,
            isBusy: Boolean,
        },
        setup(props, context) {
            // <!-- DEFINE -->
            const title = 'Add Weather Station';

            /* Functions */
            const onAddSave = (payload) => {
                const { weatherStation } = payload;
                props.onSave({ stationId: weatherStation });
            };

            const onAddCancel = () => {
                props.onCancel();
            };

            return {
                title,
                onAddSave,
                onAddCancel,
            };
        },
    });
</script>
