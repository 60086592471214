// <!-- API -->
import { computed, ref } from 'vue';

// <!-- UTILITIES -->
import { useNARAStandardIndex } from '@/hooks/cache/useNARAStandardIndex';
import useECNBCache, { ECNBCache } from '@/hooks/store/useECNBCache';

// <!-- TYPES -->

/**
 * Prepare standrds index data.
 */
export const useNARAStandardsData = (store) => {
    // <!-- COMPOSE -->
    const cache = useECNBCache(store);
    const standards = useNARAStandardIndex(cache);

    // <!-- COMPUTE -->

    /** @type {V.ComputedRef<Boolean>} Is data being fetched? */
    const isLoading = computed(() => {
        const isFetchingStandards = standards.isFetching.value;
        return isFetchingStandards;
    });

    // <!-- ACTIONS -->

    /**
     * Get the standards and hierarchy data from their respective caches.
     */
    const fetchData = async () => {
        const $standards = await standards.refreshNARAStandardsIndex();
        return [...$standards];
    };

    // <!-- EXPOSE -->
    return {
        isLoading,
        fetchData,
    };
};

export const useNARAStandardOptions = (store) => {
    /** ECNB Cache reference */
    const cache = useECNBCache(store);
    const { NARAStandards } = useNARAStandardIndex(cache);

    const getOptions = () => {
        const prompt = {
            label: 'Select Standard',
            value: 'placeholder',
            attrs: { disabled: true },
        };
        const options = NARAStandards.value.map((val) => {
            return {
                label: val.standard,
                value: val.id,
                attrs: {},
            };
        });

        return [prompt, ...options];
    };

    return { getOptions };
};
