// <!-- API -->
import { ref, toRefs } from 'vue';

// <!-- CLASSES -->
/**
 * @class
 * Configuration used for standardizing a composable's creation.
 * @template {Record<String, any>} [Props=any]
 * @template {V.EmitsOptions} [E=any]
 * @template {ComposableModule} [Constants=ComposableModule]
 * @template {ComposableModule} [State=ComposableModule]
 * @template {ComposableModule} [Properties=ComposableModule]
 * @template {ComposableModule} [Handlers=ComposableModule]
 * @template {ComposableModule} [Methods=ComposableModule]
 */
export class ComposableConfig {
    /**
     * Create instance of the location form context.
     * @param {Props} props Props passed into the component.
     * @param {V.SetupContext<E>} context Setup context passed into the component.
     */
    constructor(props, context) {
        // Assign the props and context to the configuration.
        this.props = props;
        this.context = context;

        // Configuration state.
        this.initialized = ref(false);

        /** @type {Constants} Local constants, once initialized. */
        this.constants = null;
        /** @type {State} Local state, once initialized. */
        this.state = null;
        /** @type {Properties} Computed properties, once initialized. */
        this.properties = null;
        /** @type {Handlers} Event handlers, once initialized. */
        this.handlers = null;
        /** @type {Methods} Methods and actions, once initialized. */
        this.methods = null;
    }

    /**
     * Get the {@link initialized} property value.
     */
    get isInitialized() {
        return this.initialized.value;
    }

    /**
     * Set the {@link initialized} property value.
     */
    set isInitialized(value) {
        this.initialized.value = value;
    }

    /**
     * Initialize the specified module.
     * @returns {this}
     */
    initializeConstants() {
        throw new TypeError(
            'Initialization method not implemented. Please override in the child implementation.'
        );
    }

    /**
     * Initialize the specified module.
     * @returns {this}
     */
    initializeState() {
        throw new TypeError(
            'Initialization method not implemented. Please override in the child implementation.'
        );
    }

    /**
     * Initialize the specified module.
     * @returns {this}
     */
    initializeProperties() {
        throw new TypeError(
            'Initialization method not implemented. Please override in the child implementation.'
        );
    }

    /**
     * Initialize the specified module.
     * @returns {this}
     */
    initializeHandlers() {
        throw new TypeError(
            'Initialization method not implemented. Please override in the child implementation.'
        );
    }

    /**
     * Initialize the specified module.
     * @returns {this}
     */
    initializeMethods() {
        throw new TypeError(
            'Initialization method not implemented. Please override in the child implementation.'
        );
    }
}

/**
 * @class
 * Composable module used for standardizing a composable's creation.
 * @template {Object} [Config=any]
 */
export class ComposableModule {
    /**
     * Create module with configuration settings.
     * @param {String} module Property name for the module.
     * @param {Config} config Input configuration.
     */
    constructor(module, config) {
        this.module = module;
        this.config = config;
        config[module] = this;
    }
}

// <!-- EXPORTS -->
export default {
    ComposableConfig,
    ComposableModule,
};
