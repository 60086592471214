<template>
    <div
        class="flex flex-nowrap flex-row h-full w-full align-middle items-center justify-around"
    >
        <BaseTooltip
            v-if="isExportAllowed"
            target="#tooltip-target"
            text="Download Datasets Batch"
            :textSize="'text-md'"
            :width="220"
        >
            <template #icon>
                <VariantButton
                    variant="icon"
                    alt="Download Dataset Batch"
                    @click.prevent="params.handleExport($event, params.node.id)"
                >
                    <DocumentDownloadIcon
                        class="h-4 w-4"
                        aria-hidden="true"
                    />
                </VariantButton>
            </template>
        </BaseTooltip>
        <BaseTooltip
            v-else
            target="#tooltip-target"
            text="Legacy dataset batches cannot be exported."
            :textSize="'text-sm'"
            :width="320"
        >
            <template #icon>
                <VariantButton
                    variant="icon"
                    alt="Download Dataset Batch"
                    class="hover:cursor-not-allowed"
                    :disabled="true"
                    @click.prevent="
                        () => {
                            console.log(`Clicked ${params.node.id}`);
                        }
                    "
                >
                    <DocumentDownloadIcon
                        class="h-4 w-4"
                        aria-hidden="true"
                    />
                </VariantButton>
            </template>
        </BaseTooltip>
        <BaseTooltip
            target="#tooltip-target"
            text="Delete Dataset Batch"
            :textSize="'text-md'"
            :width="180"
        >
            <template #icon>
                <VariantButton
                    v-if="!!isNotDataAnalyst"
                    variant="danger-icon"
                    alt="Delete Dataset Batch"
                    @click.prevent="params.handleDelete($event, params.node.id)"
                >
                    <TrashIcon
                        class="h-4 w-4"
                        aria-hidden="true"
                    />
                </VariantButton>
            </template>
        </BaseTooltip>
    </div>
</template>

<script>
    // <!-- API -->
    import { defineComponent } from 'vue';
    import { computedEager } from '@vueuse/shared';

    // <!-- COMPONENTS -->
    import { DocumentDownloadIcon, TrashIcon } from '@heroicons/vue/outline';
    import VariantButton from '@/components/buttons/VariantButton.vue';
    import BaseTooltip from '@/components/tooltips/BaseTooltip.vue';

    // <!-- COMPOSABLES -->
    import { useDataAnalystGate } from '@/hooks/gates';

    // <!-- TYPES -->
    /**
     * @typedef {AgGrid.ICellRendererParams & { isExportable: Function, handleExport: Function, handleDelete: Function }} DatasetBatchesTableIconsParams
     */

    // <!-- DEFINITION -->
    export default defineComponent({
        name: 'DatasetBatchesTableIcons',
        components: {
            DocumentDownloadIcon,
            TrashIcon,
            VariantButton,
            BaseTooltip,
        },
        props: {
            params: {
                /** @type {import('vue').PropType<DatasetBatchesTableIconsParams>} */
                type: Object,
            },
        },
        setup(props, context) {
            // Check computeds.
            const isExportAllowed = computedEager(() => {
                const id = props.params.node.id;
                const exportable = props.params.isExportable(id); // props.params.data.enable_download === true;
                // console.dir({ id, exportable });
                return exportable === true;
            });
            // ==== COMPOSE ====
            const { isNotDataAnalyst } = useDataAnalystGate();

            // Expose
            return {
                isExportAllowed,
                isNotDataAnalyst,
            };
        },
    });
</script>

<style lang="scss">
    div.ag-theme-alpine.ag-cell {
        padding: 0px;
        margin: auto 1px;
    }
</style>
